import { jsx as _jsx } from "react/jsx-runtime";
import { CanvasAI, services } from "@cosmos-data-studio/canvas-ai";
import { getFrameworkServiceUrl, getMicroAppVars } from "@sparky/framework";
services.setApiHost(getMicroAppVars("copilot-data-studio")?.api_server_url
    ? `https://${getMicroAppVars("copilot-data-studio")?.api_server_url}`
    : getFrameworkServiceUrl("cosmos"));
import("@cosmos-data-studio/canvas-ai/style.css");
// import "./global.css";
export default (props) => {
    const theme = "dark";
    return _jsx(CanvasAI, { ...props, theme: theme });
};
