const settingsKey = "__canvasAI_apiHostOverride";

export const appSettings = {
  get apiHostOverride() {
    return localStorage.getItem(settingsKey) || "";
  },

  setAPIHostOverride(apiHost: string) {
    localStorage.setItem(settingsKey, apiHost);
  },
};

declare global {
  export interface Window {
    __appSettings: typeof appSettings;
  }
}

window.__appSettings = appSettings;
