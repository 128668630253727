import { lazy, Suspense } from "react";
import type {
  CanvasAIProps,
  CanvasAIDependencies,
  Theme,
} from "./CanvasAITypes";

import services from "./config/services";

export { services };

const LazyCanvasAI = lazy(() => import("./CanvasAI"));

export const CanvasAI = (props: CanvasAIProps) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <LazyCanvasAI {...props} />
    </Suspense>
  );
};

export type { CanvasAIProps, CanvasAIDependencies, Theme };
